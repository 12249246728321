<template>
  <Fieldset legend="จัดการข้อมูลพื้นที่">
    <div class="p-d-flex">
      <div class="p-mb-2">
        <Button
          label="เพิ่มข้อมูล"
          icon="pi pi-plus"
          class="p-button-primary p-button-sm p-mr-2 p-mb-2"
          @click="$router.push('/area/form')"
        />
      </div>
      <div class="p-mb-2">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="dataFilters['global'].value"
            placeholder="ค้นหาข้อมูลพื้นที่"
          />
        </span>
      </div>
    </div>

    <DataTable
      :value="areas"
      ref="dt"
      dataKey="id"
      :paginator="true"
      :rows="20"
      v-model:filters="dataFilters"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[20, 50, 100]"
      currentPageReportTemplate="แสดง {first} ถึง {last} จากข้อมูล {totalRecords}"
      class="p-datatable-gridlines p-datatable-striped p-datatable-responsive-demo"
    >
      <!-- ื่อ , นามสกุล , User_name, ระดับ User -->

      <Column field="areaCode" header="รหัสพื้นที่" :sortable="true"></Column>
      <Column field="areaName" header="ชื่อพื้นที่" :sortable="true"></Column>
      <Column
        field="contactPerson"
        header="ชื่อผู้ติดต่อ"
        :sortable="true"
      ></Column>
      <Column field="amphureNameTh" header="อำเภอ" :sortable="true"></Column>
      <Column field="provinceNameTh" header="จังหวัด" :sortable="true"></Column>
      <Column
        :exportable="false"
        header="จัดการ"
        style="width: 10%; white-space: nowrap"
      >
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-sm p-button-success p-mr-2"
            @click="editElder(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-sm p-button-danger"
            @click="() => confirmDeleteAreaDialog(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </Fieldset>
  <!-- Delete Dialog -->
  <ConfirmDelete @onConfirmDeleted="confirmDeleted" />
  <!--/ Delete Dialog -->
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import ConfirmDelete from '@/components/dialogs/ConfirmDelete'
export default {
  name: 'datatable',
  layout: 'Private',
  data() {
    return {
      entity: null,
      dataFilters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    }
  },
  computed: {
    areas() {
      return this.$store.state.area.areas.map((area) => {
        const { amphure } = area
        //console.log("amphure ::==", amphure);
        return {
          ...area,
          amphureNameTh: amphure?.nameTh,
          provinceNameTh: amphure?.province?.nameTh
        }
      })
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      // save activity log
      const { codeId: userId, userLevel, area } = this.$store.getters[
        'oauth/session'
      ]
      this.$store.dispatch('activity/saveActionActivityLog', {
        actMenu: 'MANAGE_AREA'
      })
    } catch (error) {
      //console.log('error ::==',error)
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    confirmDeleteAreaDialog(entity) {
      this.entity = entity
      this.$store.commit('dialog/setConfirmDelete', true)
    },
    async confirmDeleted() {
      try {
        console.log('this.elder ::==', this.entity)
        const { codeId: pkId } = this.entity
        await this.$store.dispatch('area/deleteArea', pkId)
        this.$store.commit('dialog/setConfirmDelete', false)
      } catch (error) {
        //console.log('error ::==',error)
        this.$toast.add({
          severity: 'error',
          summary: 'เกิดข้อผิดพลาดจากระบบ',
          detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
          life: 3000
        })
      }
    },
    editElder(entity) {
      this.entity = entity
      const { codeId: pkId } = this.entity
      //this.$store.commit('dialog/setConfirmDelete',true)
      this.$router.push({
        //path: `/elder/form/${codeId}`,
        name: 'AreaFormId',
        params: { id: pkId }
      })
    }
  },
  components: {
    ConfirmDelete: ConfirmDelete
  }
}
</script>